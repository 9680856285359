<template>
  <v-dialog
    v-model="dialogVisible"
    :max-width="maxWidth + 'px'"
    :attach="attach"
    :fullscreen="fullscreen"
    persistent
    :min-height="minHeight"
  >
    <v-card class="pa-2 flex1">
      <v-card-title
        v-if="showTitle"
        class="d-flex justify-space-between fc-black-06 fs-14 fb-650"
      >
        <span class="headline">{{ title }}</span>
        <v-icon icon="mdi-close" @click="toggleDialog" class="fc-gray"></v-icon>
      </v-card-title>

      <v-card-text style="overflow: auto">
        <slot></slot>
      </v-card-text>
      <v-divider class="mb-4" v-if="showConfirm || showCancel"></v-divider>
      <div
        class="shopmall-dialog-actions text-right"
        v-if="showConfirm || showCancel"
      >
        <v-btn
          class="shopmall-dialog-actions--cancel mr-4"
          @click="cancel"
          v-if="showCancel"
          >{{ cancelText }}</v-btn
        >
        <v-btn
          class="shopmall-dialog-actions--confirm"
          color="primary"
          @click="confirm"
          :loading="confirmLoading"
        >
          <v-icon v-if="confirmIcon" :icon="confirmIcon" class="mr-2"> </v-icon>
          {{ confirmText }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
const props = defineProps({
  confirmText: {
    type: String,
    default: "OK",
  },
  cancelText: {
    type: String,
    default: "Cancel",
  },
  showConfirm: {
    type: Boolean,
    default: true,
  },
  showCancel: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  maxWidth: {
    type: Number,
    default: 600,
  },
  autoClose: {
    // 组件内部控制弹窗关闭,false时由父组件去处理关闭逻辑
    type: Boolean,
    default: true,
  },
  attach: {
    type: String,
    default: "",
  },
  confirmIcon: {
    type: String,
    default: "",
  },
  showConfirmLoading: {
    type: Boolean,
    default: true,
  },
  minHeight: {
    type: Number,
    default: 100,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
  fullscreen:{
    type:Boolean,
    default:true
  }
});

// 定义watch

// // 定义确定和取消自定义事件
const emit = defineEmits(["confirm", "cancel"]);

const dialogVisible = ref(false);

// toggle dialog
const toggleDialog = () => {
  dialogVisible.value = !dialogVisible.value;
};

// 确认
const confirmLoading = ref(false);
const confirm = () => {
  props.showConfirmLoading && (confirmLoading.value = true);

  emit("confirm");
  if (props.autoClose) {
    toggleDialog();
  }
};

// 隐藏confirmLoading
const hideLoading = () => {
  if (confirmLoading.value) {
    confirmLoading.value = false;
  }
};

const cancel = () => {
  hideLoading();
  emit("cancel");
  toggleDialog();
};

watch(
  () => dialogVisible,
  (val) => {
    if (!val) {
      confirmLoading.value = false;
    }
  }
);

defineExpose({
  show: toggleDialog,
  hideLoading,
});
</script>

<style lang="scss" scoped>
  :deep(.v-overlay__content){
      height: auto !important;
      top: auto !important;
      left: auto !important;
  }

</style>
